<template>
      <!-- iq-card -->
      <b-col xs="12" sm="12" md="12" lg="12" xl="12">
        <iq-card>
          <template v-slot:body>
          <b-row class="details">
            <!-- beneficiary -->
            <b-table-simple striped bordered small hover>
            <tbody>
              <b-tr>
                <b-th colspan="1">{{ $t('globalTrans.organization') }}</b-th>
                <b-td colspan="5">{{ getOrgName(visitHour.org_id) }}</b-td>
              </b-tr>

              <b-tr>
                <b-th colspan="1">{{ $t('VirtualMuseum.configuration.num_of_slot') }}</b-th>
                <b-td colspan="5">{{ $n(visitHour.no_of_slot_per_day) }}</b-td>
              </b-tr>
            </tbody>
           </b-table-simple>
           <table class="table table-bordered">
                <thead style="background:#e5e3e3">
                    <tr>
                        <th style="border-bottom:2px solid #66cc99" >{{$t('globalTrans.sl_no')}}</th>
                        <th style="border-bottom:2px solid #66cc99" >{{$t('VirtualMuseum.configuration.open_time')}}</th>
                        <th style="border-bottom:2px solid #66cc99" >{{$t('VirtualMuseum.configuration.close_time')}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in visitHour.details" :key="index">
                        <td>
                            {{$n(index+1)}}
                        </td>
                        <td>
                            {{getFormatedTime(item.opening_time)}}
                        </td>
                        <td>
                            {{getFormatedTime(item.closeing_time)}}
                        </td>
                    </tr>
                </tbody>
            </table>
         </b-row>
          <b-col class="text-right">
                  <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
         </b-col>
          </template>
        </iq-card>
      </b-col>
</template>

<script>
import { core } from '@/config/pluginInit'

export default {
  props: ['id'],
  components: {
  },
  created () {
    if (this.id) {
      const tmp = this.getVisitData()
      this.visitHour = tmp
      this.visitHour.details = tmp.details
      this.visitHour.edit_id = this.id
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
        loadingState: false,
        saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
        visitHour: {
            org_id: 0,
            no_of_slot_per_day: '',
            details: [],
            edit_id: ''
        }
    }
  },
  methods: {
    getVisitData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return tmpData === undefined ? {} : JSON.parse(JSON.stringify(tmpData))
    },
    getOrgName (id) {
          const objectData = this.$store.state.commonObj.organizationProfileList.find(item => item.value === id)
              if (this.$i18n.locale === 'bn') {
                  return objectData.text_bn
              } else {
                  return objectData.text_en
              }
      },
      getFormatedTime (tm) {
        if (this.$i18n.locale === 'bn') {
                  const myArray = tm.split(':')
            // return (this.$i18n.locale === 'bn' ? '০' : '0') + this.$n(tm, { useGrouping: false })
                  return this.$n(myArray[0], { useGrouping: false }) + ':' + this.$n(myArray[1], { useGrouping: false }) + ':' + this.$n(myArray[2], { useGrouping: false })
              } else {
                  return tm
              }
      }
  }
}
</script>
