<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-overlay :show="loadingState">
                        <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                            <b-row>
                                <b-col lg="5" md="5" sm="12" xs="12">
                                    <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                                      <b-form-group
                                          class="row"
                                          label-cols-sm="5"
                                          label-for="org_id"
                                          slot-scope="{ valid, errors }"
                                      >
                                          <template v-slot:label>
                                          {{$t('globalTrans.organization')}} <span class="text-danger">*</span>
                                          </template>
                                          <b-form-select
                                          plain
                                          v-model="visitHour.org_id"
                                          :options="orgList"
                                          id="org_id"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                          >
                                          <template v-slot:first>
                                          <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                          </template>
                                          </b-form-select>
                                          <div class="invalid-feedback">
                                          {{ errors[0] }}
                                          </div>
                                      </b-form-group>
                                      </ValidationProvider>
                                  </b-col>
                                  <b-col lg="5" md="5" sm="12" xs="12">
                                      <ValidationProvider name="Number of Slot" vid="slot_num_id" rules="required|integer|min_value:1|max_value:10">
                                          <b-form-group
                                              class="row"
                                              label-cols-sm="7"
                                              label-for="slot_num_id"
                                              slot-scope="{ valid, errors }"
                                          >
                                              <template v-slot:label>
                                              {{$t('VirtualMuseum.configuration.num_of_slot')}} <span class="text-danger">*</span>
                                              </template>
                                              <b-form-input
                                              id="slot_num_id"
                                              v-model="visitHour.no_of_slot_per_day"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                              ></b-form-input>
                                              <div class="invalid-feedback">
                                              {{ errors[0] }}
                                              </div>
                                          </b-form-group>
                                      </ValidationProvider>
                                  </b-col>
                                  <b-col lg="2" md="2" sm="12" xs="12" class="text-right">
                                      <b-button style="width:100%" type="button" variant="primary" @click="getSlot()" class="mr-2">{{$t('VirtualMuseum.configuration.add')}} </b-button>
                                  </b-col>
                            </b-row>
                            <b-row v-if="visitHour.no_of_slot_per_day > 0">
                                    <table class="table table-bordered" style="margin: 2px 15px" v-for="(item, index) in visitHour.details" :key="index">
                                      <thead style="background:#e5e3e3">
                                        <th v-if="id" colspan="3" style="border-bottom:2px solid #66cc99">{{$t('VirtualMuseum.configuration.slot') + ' ' + (index+1) }}</th>
                                        <th v-else colspan="2" style="border-bottom:2px solid #66cc99">{{$t('VirtualMuseum.configuration.slot') + ' ' + (index+1) }}</th>
                                      </thead>
                                      <tbody>
                                        <td>
                                            <b-col lg="12" md="12" sm="12" xs="12">
                                                <ValidationProvider name="Open Time" :vid="`open_time_id${index}`" rules="required">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="5"
                                                        :label-for="`open_time_id${index}`"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                        <template v-slot:label>
                                                        {{$t('VirtualMuseum.configuration.open_time')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-input
                                                        :id="'open_time_id'+index"
                                                        type="time"
                                                        v-model="item.opening_time"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                        </td>
                                        <td>
                                            <b-col lg="12" md="12" sm="12" xs="12">
                                                <ValidationProvider name="Close Time" vid="close_time_id" rules="required">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="5"
                                                        label-for="close_time_id"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                        <template v-slot:label>
                                                        {{$t('VirtualMuseum.configuration.close_time')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-input
                                                        id="close_time_id"
                                                        type="time"
                                                        v-model="item.closeing_time"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                        </td>
                                        <td v-if="id">
                                            <b-button variant="danger" class="ri-delete-bin-line m-0" @click="removeTableRow(index)"></b-button>
                                        </td>
                                      </tbody>
                                    </table>
                            </b-row> <br>
                            <div class="row">
                              <div class="col-sm-3"></div>
                              <div class="col text-right">
                                  <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                  &nbsp;
                                  <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-3')">{{ $t('globalTrans.cancel') }}</b-button>
                              </div>
                            </div>
                        </b-form>
                    </b-overlay>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { virtualMuseumServiceBaseUrl } from '@/config/api_config'
import { visitingHourSaveApi } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getVisitData()
      this.visitHour = tmp
      this.visitHour.details = tmp.details
      this.visitHour.edit_id = this.id
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
        loadingState: false,
        saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
        visitHour: {
            org_id: 0,
            no_of_slot_per_day: '',
            details: [],
            edit_id: ''
        }
    }
  },
  computed: {
      orgList: function () {
          const objectData = this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0 && item.value === 12)
        //   const objectData = this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
          return objectData.map((obj, key) => {
              if (this.$i18n.locale === 'bn') {
                  return { value: obj.value, text: obj.text_bn }
              } else {
                  return { value: obj.value, text: obj.text_en }
              }
          })
      }
  },
  methods: {
    removeTableRow (index) {
      this.visitHour.details.splice(index, 1)
    },
    getSlot () {
      this.visitHour.details = []
      if (this.visitHour.no_of_slot_per_day < 11) {
        for (var i = 0; i < this.visitHour.no_of_slot_per_day; i++) {
          this.visitHour.details.push({
              opening_time: '',
              closeing_time: ''
          })
        }
      }
    },
    getVisitData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return tmpData === undefined ? {} : JSON.parse(JSON.stringify(tmpData))
    },
    async saveData () {
      this.loadingState = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }

      result = await RestApi.postData(virtualMuseumServiceBaseUrl, visitingHourSaveApi, this.visitHour)

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
            this.loadingState = false
            this.$store.dispatch('SeedsFertilizer/mutateCommonObj', { hasDropdownLoaded: false })
            this.$toast.success({
            title: 'Success',
            message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
            })

            this.$bvModal.hide('modal-3')
      } else {
            this.loadingState = false
            this.$refs.form.setErrors(result.errors)
      }
    }
  }
}
</script>
